import(/* webpackMode: "eager" */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\src\\components\\layouts\\BasicHomeLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\src\\components\\molecules\\CustomizeErrorBoundary.tsx");
;
import(/* webpackMode: "eager" */ "C:\\Users\\base\\Documents\\Traffix\\front-end\\traffic-fe-landding-customer\\src\\components\\templates\\Home.tsx");
