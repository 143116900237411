'use client';

import type { ReactNode } from 'react';
import { useLayoutEffect } from 'react';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import Header from '@/components/molecules/Header';
const Footer = dynamic(() => import('@/components/molecules/Footer'), {
  ssr: false
});
const BasicHomeLayout = ({
  children
}: {
  children: ReactNode;
}) => {
  const pathname = usePathname();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);
  return <div className="min-h-full h-full">
      <Header />
      <div className="pt-16 min-h-full h-full">
        {children}
        <Footer />
      </div>
    </div>;
};
export default BasicHomeLayout;